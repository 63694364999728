import React from 'react'
import styled from 'styled-components'

const Block = styled.a`
  position: absolute;
  display: block;
  top: ${props => props.y};
  left: ${props => props.x};
  width: ${props => props.w};
  height: ${props => props.h};
  z-index: 2;
  text-decoration: none;
`

const Maplink = ({x,y, href, w = '50px', h='10%'}) => {
  return (
    <Block href={href} x={x} y={y} w={w} h={h}>&nbsp;</Block>
  )
}

export default Maplink;
