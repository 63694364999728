import React from 'react'
import Gallery from '../../components/gallery'

const GalleryPage = () => {
  return (
    <><Gallery /></>
  )
}

export default GalleryPage;
