import React from "react"
import styled from "styled-components"
import Fade from "react-reveal/Fade"
import Maplink from "../maplink"

import Image1 from "../../images/10kt-diamond-star-hoop-earrings.jpg"
import Image2 from "../../images/pave-zircon-plain-double-cuff-hoop.jpg"
import Image3 from "../../images/plain-cuff-hoop-zircon-heart-cuff-hoop.jpg"
import Image4 from "../../images/plain-pearl-cuff-hoop.jpg"
import Image5 from "../../images/quartz-crystal-snake-chain-bracelet.jpg"
import Image6 from "../../images/simple-medium-hoop-earrings.jpg"
import Image7 from "../../images/zircon-heart-double-length-chain-necklace.jpg"
import Image8 from "../../images/zircon-heart-ring-10k-yellow-gold-diamond.jpg"
import Image9 from "../../images/zircon-heart-ring-10k-yellow-gold-diamond2.jpg"
import Image10 from "../../images/zircon-star-hoop-earrings.jpg"
import HomeBG from "../../images/home.jpg"

const Row = styled.div`
  display: flex;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 768px) {
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
  }
`

const Half = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px;
  @media (max-width: 768px) {
    padding: 30px 30px 0px 30px;
  }
  img {
    max-width: 100%;
  }
`

const Product = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  max-width: ${props => (props.mw ? props.mw : "50vw")};
  img {
    max-width: 100%;
    &.first {
      margin-top: 30px;
    }
  }
  p {
    text-transform: uppercase;
    font-size: 8px;
    line-height: normal;
    min-height: 40px;
    a {
      text-decoration: none;
      color: #000;
    }
  }
`

const ImageContainer = styled.div`
  position: relative;
  img {
    width: ${props => (props.w ? props.w : "100%")};
  }
`

const ProductContainer = styled.div`
  display: flex;
  width: 90vw;
  margin-left: auto;
  margin-right: auto;
  justify-content: ${props => props.align};
  @media (max-width: 768px) {
  }
`

const getRandomNumber = (min, max) => {
  return Math.random() * (max - min) + min
}

// <ProductContainer>
// <Product>
//   <ImageContainer>
//     <Maplink href="/10kt-diamond-star-hoop-earrings" x="26%" y="49%" />
//     <Maplink href="/10kt-diamond-star-hoop-earrings" x="76%" y="49%" />
//     <img src={Image1} />
//   </ImageContainer>
//   <p><a href="/10kt-diamond-star-hoop-earrings">10KT DIAMOND STAR HOOP EARRINGS</a></p>
// </Product>
// </ProductContainer>

// <ProductContainer>
// <Product>
// <ImageContainer>
//   <Maplink href="/plain-cuff-hoop-earrings" x="54%" y="40%" />
//   <Maplink href="/zircon-heart-cuff-hoop-earrings" x="44%" y="40%" />
//   <img src={Image3} />
// </ImageContainer>
// <p>
//   <a href="/plain-cuff-hoop-earrings">PLAIN CUFF HOOP EARRINGS</a><br />
//   <a href="/zircon-heart-cuff-hoop-earrings">ZIRCON HEART CUFF HOOP EARRINGS</a>
// </p>
// </Product>
// </ProductContainer>

const Gallery = () => {
  return (
    <>
      <ProductContainer align="flex-end">
        <Product>
          <ImageContainer>
            <Maplink href="/zircon-star-hoop-earrings" x="45%" y="41%" />
            <img src={Image10} className="first" />
          </ImageContainer>
          <p>
            <a href="/zircon-star-hoop-earrings">ZIRCON STAR HOOP EARRINGS</a>
          </p>
        </Product>
      </ProductContainer>

      <ProductContainer align="flex-start">
        <Product landscape mw="70vw">
          <ImageContainer>
            <Maplink href="/plain-cuff-hoop-earrings" x="53%" y="34%" />
            <Maplink href="/pearl-cuff-hoop-earrings" x="43%" y="34%" />
            <img src={Image4} />
          </ImageContainer>
          <p>
            <a href="/pearl-cuff-hoop-earrings">PEARL CUFF HOOP EARRINGS</a>
            <br />
            <a href="/plain-cuff-hoop-earrings">Plain Cuff Hoop Earrings</a>
          </p>
        </Product>
      </ProductContainer>

      <ProductContainer align="center">
        <Product>
          <ImageContainer>
            <Maplink
              href="/zircon-heart-double-length-chain-necklace"
              x="22%"
              y="49%"
              w="32%"
            />
            <img src={Image7} />
          </ImageContainer>
          <p>
            <a href="/zircon-heart-double-length-chain-necklace">
              ZIRCON HEART DOUBLE LENGTH CHAIN NECKLACE
            </a>
          </p>
        </Product>
      </ProductContainer>

      <ProductContainer align="center">
        <Product>
          <ImageContainer>
            <Maplink
              href="/quartz-crystal-snake-chain-bracelet"
              x="41%"
              y="34%"
              w="25%"
            />
            <img src={Image5} />
          </ImageContainer>
          <p>
            <a href="/quartz-crystal-snake-chain-bracelet">
              QUARTZ CRYSTAL SNAKE CHAIN BRACELET
            </a>
          </p>
        </Product>
      </ProductContainer>

      <ProductContainer align="flex-end">
        <Product landscape mw="80vw">
          <ImageContainer w="70vw">
            <Maplink
              href="/pave-zircon-plain-double-cuff-hoop-earrings"
              x="49%"
              y="42%"
              w="11%"
              h="35px"
            />
            <img src={Image2} />
          </ImageContainer>
          <p>
            <a href="/pave-zircon-plain-double-cuff-hoop-earrings">
              PAVE ZIRCON & PLAIN DOUBLE CUFF HOOP EARRINGS
            </a>
          </p>
        </Product>
      </ProductContainer>

      <ProductContainer align="center">
        <Product>
          <ImageContainer>
            <Maplink
              href="/zircon-heart-ring"
              x="24%"
              y="49%"
              w="11%"
              h="30px"
            />
            <Maplink
              href="/10k-yellow-gold-diamond-ring"
              x="48%"
              y="52%"
              w="10%"
              h="30px"
            />
            <Maplink
              href="/zircon-heart-ring"
              x="66%"
              y="52%"
              w="11%"
              h="30px"
            />
            <img src={Image9} />
          </ImageContainer>
          <p>
            <a href="/zircon-heart-ring">Zircon Heart Ring</a>
            <br />
            <a href="/10k-yellow-gold-diamond-ring">
              10K YELLOW GOLD & DIAMOND RING
            </a>
          </p>
        </Product>
      </ProductContainer>

      <ProductContainer align="center">
        <Product>
          <ImageContainer>
            <Maplink
              href="/simple-medium-hoop-earrings"
              x="41%"
              y="44%"
              w="15%"
            />
            <Maplink
              href="/simple-medium-hoop-earrings"
              x="7%"
              y="64%"
              w="15%"
            />
            <img src={Image6} />
          </ImageContainer>
          <p>
            <a href="/simple-medium-hoop-earrings">
              SIMPLE MEDIUM HOOP EARRINGS
            </a>
          </p>
        </Product>
      </ProductContainer>

      <ProductContainer align="flex-start">
        <Product landscape mw="70vw">
          <ImageContainer>
            <Maplink
              href="/gypsy-hoop-earrings"
              x="48%"
              y="42%"
              w="11%"
              h="17%"
            />
            <img src={HomeBG} />
          </ImageContainer>
          <p>
            <a href="/gypsy-hoop-earrings">GYPSY HOOP EARRINGS</a>
          </p>
        </Product>
      </ProductContainer>
    </>
  )
}

export default Gallery
